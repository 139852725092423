var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[(!_vm.pageLoad)?_c('PageLoader'):_vm._e(),_c('div',{staticClass:"page-title d-flex py-2 justify-content-between"},[(_vm.edit)?_c('h1',{staticClass:"h4 mb-0"},[_vm._v(" Редактирование заявки "),(_vm.currentOrder)?_c('span',[_vm._v("№ "+_vm._s(_vm.currentOrder.number)+" от "+_vm._s(_vm._f("parseDate")(_vm.currentOrder.order_date)))]):_vm._e()]):_c('h1',{staticClass:"h4 mb-0"},[_vm._v("Создание заказа на поставку")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('BButton',{directives:[{name:"b-modal",rawName:"v-b-modal.confirm-delete",modifiers:{"confirm-delete":true}}],staticClass:"ml-3",attrs:{"variant":"warning"}},[_vm._v("Удалить ")]),_c('BButton',{staticClass:"ml-3",attrs:{"variant":"success"},on:{"click":_vm.save}},[_vm._v("Сохранить ")]),_c('BButton',{staticClass:"ml-3",attrs:{"variant":"primary"},on:{"click":_vm.sendApproveOrder}},[_vm._v("Отправить на согласование ")])],1)]),_c('b-tabs',{attrs:{"card":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":"Информация"}},[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Срок исполнения по заказу ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"locale":"ru-RU","placeholder":"Выберите дату","state":_vm.$v.sendData.deadline_date.$error
                  ? _vm.$v.sendData.deadline_date.required
                  : null,"date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }},model:{value:(_vm.sendData.deadline_date),callback:function ($$v) {_vm.$set(_vm.sendData, "deadline_date", $$v)},expression:"sendData.deadline_date"}})],1)])]),_c('div',{staticClass:"h5"},[_vm._v("Заказчик-застройщик")]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Филиал АО «Сетевая компания» ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('v-select',{class:{
                'v-select-error': _vm.$v.sendData.customer.organization_id.$error,
              },attrs:{"label":"text","appendToBody":true,"options":_vm.organizationsOptions.slice(1),"placeholder":_vm.organizationsOptions
                  .slice(1)
                  .find(
                    function (el) { return el.value === _vm.sendData.customer.organization_id; }
                  )
                  ? _vm.organizationsOptions
                      .slice(1)
                      .find(
                        function (el) { return el.value === _vm.sendData.customer.organization_id; }
                      ).text
                  : '',"value":_vm.organizationsOptions
                  .slice(1)
                  .find(
                    function (el) { return el.value === _vm.sendData.customer.organization_id; }
                  )},on:{"input":function (value) { return (_vm.sendData.customer.organization_id = value.value); },"search":_vm.getOrganizationsOptionsByQuery},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Нет результатов. ")]},proxy:true}])})],1)]),_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Договор подряда ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('v-select',{class:{
                'v-select-error':
                  _vm.$v.sendData.customer.work_agreement_id.$error,
              },attrs:{"label":"text","appendToBody":true,"options":_vm.workAgreementsOptions.slice(1),"placeholder":_vm.workAgreementsOptions
                  .slice(1)
                  .find(
                    function (el) { return el.value === _vm.sendData.customer.work_agreement_id; }
                  )
                  ? _vm.workAgreementsOptions
                      .slice(1)
                      .find(
                        function (el) { return el.value === _vm.sendData.customer.work_agreement_id; }
                      ).text
                  : '',"value":_vm.workAgreementsOptions
                  .slice(1)
                  .find(
                    function (el) { return el.value === _vm.sendData.customer.work_agreement_id; }
                  )},on:{"input":function (value) { return (_vm.sendData.customer.work_agreement_id = value.value); },"search":_vm.getWorkAgreementsOptionsByQuery},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Нет результатов. ")]},proxy:true}])})],1)]),_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Вид работ ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-select',{attrs:{"state":_vm.$v.sendData.customer.work_type.$error
                  ? _vm.$v.sendData.customer.work_type.required
                  : null,"options":_vm.workTypes},model:{value:(_vm.sendData.customer.work_type),callback:function ($$v) {_vm.$set(_vm.sendData.customer, "work_type", $$v)},expression:"sendData.customer.work_type"}})],1)]),_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Объект ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('v-select',{class:{
                'v-select-error': _vm.$v.sendData.customer.object_id.$error,
              },attrs:{"label":"text","options":_vm.objectsOptions,"value":_vm.objectsOptions.find(
                  function (el) { return el.value === _vm.sendData.customer.object_id; }
                ),"appendToBody":true,"placeholder":_vm.objectsOptions.find(
                  function (el) { return el.value === _vm.sendData.customer.object_id; }
                )
                  ? _vm.objectsOptions.find(
                      function (el) { return el.value === _vm.sendData.customer.object_id; }
                    ).text
                  : ''},on:{"input":function (value) { return (_vm.sendData.customer.object_id = value.value); },"search":_vm.getObjectsOptionsByQuery},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Нет результатов. ")]},proxy:true}])})],1)]),(
            _vm.subObjectsOptions(_vm.sendData.customer.object_id) &&
            _vm.subObjectsOptions(_vm.sendData.customer.object_id).length
          )?_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Подобъект ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-select',{attrs:{"options":_vm.subObjectsOptions(_vm.sendData.customer.object_id)},model:{value:(_vm.sendData.customer.sub_object_id),callback:function ($$v) {_vm.$set(_vm.sendData.customer, "sub_object_id", $$v)},expression:"sendData.customer.sub_object_id"}})],1)]):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Начало работ ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"state":_vm.$v.sendData.customer.work_start_date.$error
                      ? _vm.$v.sendData.customer.work_start_date.required
                      : null,"locale":"ru-RU","placeholder":"Выберите дату","date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }},model:{value:(_vm.sendData.customer.work_start_date),callback:function ($$v) {_vm.$set(_vm.sendData.customer, "work_start_date", $$v)},expression:"sendData.customer.work_start_date"}})],1)])]),_c('b-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Окончание работ ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"state":_vm.$v.sendData.customer.work_end_date.$error
                      ? _vm.$v.sendData.customer.work_end_date.required
                      : null,"locale":"ru-RU","placeholder":"Выберите дату","date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }},model:{value:(_vm.sendData.customer.work_end_date),callback:function ($$v) {_vm.$set(_vm.sendData.customer, "work_end_date", $$v)},expression:"sendData.customer.work_end_date"}})],1)])])],1)],1),_c('div',{staticClass:"h5"},[_vm._v("Поставщик")]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"Fd-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Поставщик ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[(_vm.isProvider)?_c('span',[_vm._v(" "+_vm._s(_vm.getContrAgent ? _vm.getContrAgent.name : "")+" ")]):_c('v-select',{class:{
                'v-select-error': _vm.$v.sendData.provider.contr_agent_id.$error,
              },attrs:{"label":"text","appendToBody":true,"options":_vm.contrAgentsOptions.slice(1),"placeholder":_vm.contrAgentsOptions
                  .slice(1)
                  .find(function (el) { return el.value === _vm.sendData.provider.contr_agent_id; })
                  ? _vm.contrAgentsOptions
                      .slice(1)
                      .find(
                        function (el) { return el.value === _vm.sendData.provider.contr_agent_id; }
                      ).text
                  : '',"value":_vm.contrAgentsOptions
                  .slice(1)
                  .find(function (el) { return el.value === _vm.sendData.provider.contr_agent_id; })},on:{"input":function (value) { return (_vm.sendData.provider.contr_agent_id = value.value); },"search":_vm.getContrAgentsOptionsByQuery},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Нет результатов. ")]},proxy:true}])})],1)]),_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Договор поставки ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-select',{attrs:{"state":_vm.$v.sendData.provider.contract_id.$error
                  ? _vm.$v.sendData.provider.contract_id.required
                  : null,"options":_vm.providersContractsOptions.slice(1)},model:{value:(_vm.sendData.provider.contract_id),callback:function ($$v) {_vm.$set(_vm.sendData.provider, "contract_id", $$v)},expression:"sendData.provider.contract_id"}})],1)]),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Контактное лицо ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-input',{attrs:{"state":_vm.$v.sendData.provider.full_name.$error
                      ? _vm.$v.sendData.provider.full_name.required
                      : null,"trim":""},model:{value:(_vm.sendData.provider.full_name),callback:function ($$v) {_vm.$set(_vm.sendData.provider, "full_name", $$v)},expression:"sendData.provider.full_name"}})],1)])]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Адрес электронной почты ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-input',{attrs:{"state":_vm.$v.sendData.provider.email.$error
                      ? _vm.$v.sendData.provider.email.required &&
                        _vm.$v.sendData.provider.email.email
                      : null,"trim":""},model:{value:(_vm.sendData.provider.email),callback:function ($$v) {_vm.$set(_vm.sendData.provider, "email", $$v)},expression:"sendData.provider.email"}})],1)])]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Телефон ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ###-##-##'),expression:"'+7 (###) ###-##-##'"}],attrs:{"state":_vm.$v.sendData.provider.phone.$error
                      ? _vm.$v.sendData.provider.phone.required
                      : null,"trim":""},model:{value:(_vm.sendData.provider.phone),callback:function ($$v) {_vm.$set(_vm.sendData.provider, "phone", $$v)},expression:"sendData.provider.phone"}})],1)])])],1)],1),_c('div',{staticClass:"h5"},[_vm._v("Подрядчик")]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Подрядчик ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[(!_vm.isContractor)?_c('b-form-select',{attrs:{"state":_vm.$v.sendData.contractor.contr_agent_id.$error
                  ? _vm.$v.sendData.contractor.contr_agent_id.required
                  : null,"options":_vm.contrAgentsOptions},model:{value:(_vm.sendData.contractor.contr_agent_id),callback:function ($$v) {_vm.$set(_vm.sendData.contractor, "contr_agent_id", $$v)},expression:"sendData.contractor.contr_agent_id"}}):_c('span',{staticStyle:{"height":"29px","line-height":"29px"}},[_vm._v(" "+_vm._s(_vm.user && _vm.user.contr_agent ? _vm.user.contr_agent.name : "")+" ")])],1)]),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Контактное лицо ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-input',{attrs:{"state":_vm.$v.sendData.contractor.full_name.$error
                      ? _vm.$v.sendData.contractor.full_name.required
                      : null,"trim":""},model:{value:(_vm.sendData.contractor.full_name),callback:function ($$v) {_vm.$set(_vm.sendData.contractor, "full_name", $$v)},expression:"sendData.contractor.full_name"}})],1)])]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Адрес электронной почты ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-input',{attrs:{"state":_vm.$v.sendData.contractor.email.$error
                      ? _vm.$v.sendData.contractor.email.required &&
                        _vm.$v.sendData.contractor.email.email
                      : null,"trim":""},model:{value:(_vm.sendData.contractor.email),callback:function ($$v) {_vm.$set(_vm.sendData.contractor, "email", $$v)},expression:"sendData.contractor.email"}})],1)])]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Телефон ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ###-##-##'),expression:"'+7 (###) ###-##-##'"}],attrs:{"state":_vm.$v.sendData.contractor.phone.$error
                      ? _vm.$v.sendData.contractor.phone.required
                      : null,"trim":""},model:{value:(_vm.sendData.contractor.phone),callback:function ($$v) {_vm.$set(_vm.sendData.contractor, "phone", $$v)},expression:"sendData.contractor.phone"}})],1)])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Ответственный на объекте ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-input',{attrs:{"state":_vm.$v.sendData.contractor.responsible_full_name.$error
                      ? _vm.$v.sendData.contractor.responsible_full_name.required
                      : null,"trim":""},model:{value:(_vm.sendData.contractor.responsible_full_name),callback:function ($$v) {_vm.$set(_vm.sendData.contractor, "responsible_full_name", $$v)},expression:"sendData.contractor.responsible_full_name"}})],1)])]),_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex table-bordered mb-1"},[_c('div',{staticClass:"px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0",staticStyle:{"width":"250px"}},[_vm._v(" Телефон ответственного ")]),_c('div',{staticClass:"px-2 py-1 flex-grow-1"},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ###-##-##'),expression:"'+7 (###) ###-##-##'"}],attrs:{"state":_vm.$v.sendData.contractor.responsible_phone.$error
                      ? _vm.$v.sendData.contractor.responsible_phone.required
                      : null,"trim":""},model:{value:(_vm.sendData.contractor.responsible_phone),callback:function ($$v) {_vm.$set(_vm.sendData.contractor, "responsible_phone", $$v)},expression:"sendData.contractor.responsible_phone"}})],1)])])],1)],1)]),_c('b-tab',{attrs:{"title":"Позиции МТР"}},[_c('CreateTable',{attrs:{"fields":_vm.positionFields,"withIterator":true,"withTotal":"sum","validations":_vm.$v.sendData.positions.$each},on:{"addRow":_vm.addMtrPosition},model:{value:(_vm.sendData.positions),callback:function ($$v) {_vm.$set(_vm.sendData, "positions", $$v)},expression:"sendData.positions"}}),_c('pre',[_vm._v(_vm._s(_vm.$v.sendData.positions.$each[0].nomenclature_id.$error))])],1)],1),_c('b-modal',{attrs:{"id":"confirm-delete","title":"Удаление заявки","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
                      var hide = ref.hide;
return [_c('b-button',{on:{"click":function($event){return hide()}}},[_vm._v("Нет")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.deleteOrder}},[_vm._v("Да ")])]}}])},[_c('p',{staticClass:"m-0"},[_vm._v("Вы действительно хотите удалить данную заявку?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }